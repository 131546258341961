$(document).ready(function() {
    $('.navbar-collapse').on('shown.bs.collapse', function() {
        $('body').css('overflow', 'hidden');
        $('body').css('height', '100vh');
        $('body').css('width', '100%');
        $('body').css('position', 'fixed');
    });

    $('.navbar-collapse').on('hidden.bs.collapse', function() {
        $('body').css('overflow', 'auto');
        $('body').css('height', 'initial');
        $('body').css('width', 'initial');
        $('body').css('position', 'initial');
    });

    $('.join-free').on( "submit", function( event ) {
        event.preventDefault();
        var actionUrl = $('.join-free').attr('action');

        $.ajax({
            url: actionUrl,
            type: 'post',
            dataType: 'application/json',
            data: $('.join-free').serialize(),
            success: function(data) {
                console.log('Success');
                alert('Your request has been sent. We back to you asap.');
            }
        });
    });

    const navLinks = $('body').find('.mobile-nav-link');
    const menuToggle = $('#navbarSupportedContent');
    const bsCollapse = new bootstrap.Collapse(menuToggle, {
        toggle: false
    });

    $.each(navLinks, function (index, element) {
        // console.log(element);
        $(element).on('click', function () {
            if(bsCollapse._isShown()){
                bsCollapse.hide();
            }
            let anchorBlockId = $(element).attr('href');

            $('html, body').animate({
                scrollTop: $(anchorBlockId).offset().top
            }, {
                queue: false
            });
        });
    });
});
